<template>
  <div class="call-to-action flex-row ai-c cursor-pointer">
    <div
      class="call-to-action__container flex-row ai-c cursor-pointer gap-half"
      v-if="!hasCustomEvent"
      @click="handleClick"
      :class="{'disable': disable, 'reverse': isReverse}">
      <div class="call-to-action__icon-wrapper flex-row ai-c jc-c" v-if="!hideIcon" :class="{'icon-bg': iconBgColor}">
        <inline-svg
          :width="iconSize"
          class="call-to-action__icon"
          :class="{'has-color': hasIconColor}" :height="iconSize" :src="require(`@/assets/icons/` + icon)" />
      </div>
      <p
        class="call-to-action__label default-text"
        v-if="label" :class="{'hide-in-mobile': hideInMobile}">
        {{ label }}
      </p>
    </div>
    <div class="call-to-action__container" v-else>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
      default: 'add.svg'
    },
    iconSize: {
      type: String,
      default: '17'
    },
    iconBgColor: {
      type: String,
    },
    iconColor: {
      type: String,
      default: '#FFA500'
    },
    fontColor: {
      type: String,
      default: '#0C0F4A'
    },
    fontSize: {
      type: String,
      default: '12px'
    },
    disable: {
      type: Boolean,
      default: false,
    },
    hasCustomEvent: {
      type: Boolean,
      default: false,
    },
    isReverse: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    hasIconColor: {
      type: Boolean,
      default: true
    },
    fontWeight: {
      type: String,
      default: '500'
    },
    hideInMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['call-to-action'],
  created() {
  },
  methods: {
    handleClick() {
      this.$emit('call-to-action');
    }
  },
};
</script>
<style lang="scss" scoped>
.call-to-action {
  &__container {
    gap: .5rem;
  }
  &__icon-wrapper {
    width: auto;
    height: auto;
    background: v-bind('$props.iconBgColor');
    border-radius: 100%;
  }
  &__icon-wrapper.icon-bg {
    padding: 0.3rem;
    box-sizing: border-box;
  }
  &__label {
    font-size: v-bind('$props.fontSize');
    font-weight: v-bind('$props.fontWeight');
    line-height: 30px;
    letter-spacing: 0px;
    white-space: nowrap;
    color: v-bind('$props.fontColor');
  }
  &__icon.has-color {
    :deep(path) {
      fill: v-bind('$props.iconColor');;
      fill-opacity: 1;
    }
  }
}
.call-to-action__container.disable {
  cursor: no-drop;
}
.reverse {
  flex-direction: row-reverse;
}
</style>
