<template>
    <div class="box-card-container">
        <!-- NOTE: DON'T USE THIS COMPONENT USE DefaultCardSlot INSTEAD! -->
        <div class="card-header-container">
          <!-- DEFAULT BOX SETTINGS -->
          <p class="card-title" v-if="!propertyId && !postcode">{{boxTitle}} <span>{{boxCount}}</span></p>
          <slot name="action"></slot>

          <p v-if="edit && !propertyId && !postcode" class="card-cta" @click="onManage">Edit</p>
          <p v-if="manage && !propertyId && !postcode" class="card-cta" @click="onManage">Manage</p>

          <!-- IF BOX IS FOR PROPERTY DETAILS -->
          <p class="card-title-id" v-if="propertyId">Property ID: <span>{{propertyId}}</span></p>
          <p class="card-title-postcode" v-if="postcode"><span><LocationFilled style="width: 1em; height: 1em;"/></span> {{postcode}}</p>

          <p class="card-title-cta default-text show-in-bigger-mobile" v-if="status && status > 3 && status < 6 && isProjectOwner" @click="setOpenEscrowDrawer(true)">
            <span><inline-svg width="13px" height="13px" :src="require(`@/assets/icons/escrow-wallet-icon.svg`)"></inline-svg></span>&nbsp;
            Project escrow wallet&nbsp;
            <span><el-icon :size="13" color="#0C0F4A"><ArrowRightBold/></el-icon></span>
          </p>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import { ArrowRightBold, LocationFilled } from '@element-plus/icons';
import {
  computed,
  defineComponent
}
  from 'vue';
import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';

import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  props: ['title', 'count', 'seeAll', 'edit', 'modalType', 'propertyId', 'postcode', 'manage', 'route', 'status'],
  components: {
    LocationFilled,
    ArrowRightBold,
  },
  setup(props) {
    const router = useRouter();

    const boxTitle = computed(() => {
      return props.title;
    });
    const boxCount = computed(() => {
      return props.count;
    });

    const routeTo = (e) => {
      router.push({ name: e });
    };

    return {
      boxTitle,
      boxCount,
      routeTo
    };
  },
  computed: {
    ...mapGetters(['isProjectOwner', 'isTradesperson']),
  },
  methods: {
    ...mapActions(['setProjectDescriptionModal', 'setProjectManageMembersModal', 'setProjectAttachmentsModal', 'setProjectBudgetModal']),
    ...mapActions(PROJECTS_STORE, ['setOpenEscrowDrawer']),

    onManage() {
      if (this.modalType) {
        if (this.modalType === 'PDescription') {
          this.setProjectDescriptionModal(true);
        } else if (this.modalType === 'PManageMembers') {
          this.setProjectManageMembersModal(true);
        } else if (this.modalType === 'PAttachments') {
          this.setProjectAttachmentsModal(true);
        } else if (this.modalType === 'PBudget') {
          this.setProjectBudgetModal(true);
        }
      } else if (this.route) {
        this.routeTo(this.route);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.box-card-container * p, .box-card-container * span {
    font-family: 'Mulish';
}
.box-card-container {
    .card-header-container {
            display:flex;
            width:100%;
            justify-content: space-between;
            align-items:center;
            margin-bottom:1rem;
        p {
            margin: 0;
        }
        .card-title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15px;
            text-align: left;

            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: left;
                color: rgba(12, 15, 74, 0.5);
            }
        }
        .card-title-id, .card-title-postcode {
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: rgba(12, 15, 74, 0.5);
            display:flex;
            justify-content: center;
            align-items: center;
            gap:.5rem;
        }
        .card-cta {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.4000000059604645px;
            text-align: right;
            color: #FFA500;
            cursor: pointer;
        }
        .card-title-cta {
          font-size: 14px;
          font-weight: 600;
          line-height: 0;
          letter-spacing: 0.15000000596046448px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
    }
}

@include media-xs-max-width() {
  .box-card-container {
      .card-header-container {
              display:flex;
              width:100%;
              justify-content: space-between;
              align-items:center;
              margin-bottom:1rem;
          p {
              margin: 0;
          }
          .card-title {
              font-size: 15px;

              span {
                  font-size: 12px;
              }
          }
          .card-title-id, .card-title-postcode {
              font-size: 15px;
          }
          .card-cta {
              font-size: 12px;
          }
          .card-title-cta {
            font-size: 12px;
          }
      }
  }
}
</style>
