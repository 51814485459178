import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_PUBLIC_PROFILE_CACHE } from '@/store/modules/attachment-cache/constants';
export default defineComponent({
    name: 'user-thumbnail',
    props: {
        source: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: '50px',
        },
        height: {
            type: String,
            default: '50px',
        },
        borderRadius: {
            type: String,
            default: '25px'
        }
    },
    data() {
        return {
            imageSource: ''
        };
    },
    created() {
        const { source } = this.$props;
        this.loadProfileImageUrl(source);
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
        loadProfileImageUrl(value) {
            if (!value) {
                return;
            }
            this.getAttachments({
                name: USER_PUBLIC_PROFILE_CACHE,
                attachments: [{ attachment: value }],
                returnFirstOnly: true,
            })
                .then(({ src }) => {
                this.imageSource = src;
            }).catch(() => { });
        },
    }
});
